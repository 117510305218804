import "core-js/modules/es.array.concat.js";
export function useCalendar(type) {
  var store = useStore();
  var isCome = computed(function () {
    return store.getters["auth/isCome"];
  });
  var duration = isCome.value ? '00:15:00' : '00:30:00';
  var minTime = isCome.value ? '04:30:00' : mukiConst.calendar.slotMinTime;
  var maxTime = mukiConst.calendar.slotMaxTime;
  var slotLabelInterval = isCome.value ? "00:15" : "01:00";

  var labelContent = function labelContent(arg) {
    var hours = arg.date.getHours();
    var ampm = hours > 11 ? "下午" : "上午";
    var num = hours > 12 ? hours - 12 : hours;
    var minutes = arg.date.getMinutes();
    if (minutes == 15 || minutes == 45) return "";
    return isCome.value ? "".concat(ampm).concat(num, "\u9EDE").concat(minutes ? minutes : "") : "".concat(ampm).concat(num, "\u9EDE");
  };

  var labelDidMount = function labelDidMount(arg) {
    // 這個callback才能存取arg.el
    var hours = arg.date.getHours();
    var now = new Date();
    var isNowHour = hours == now.getHours();
    var minutes = arg.date.getMinutes();
    var minutes15 = Math.floor(now.getMinutes() / 15) * 15;
    var isNowMinutes = minutes == minutes15;

    if (isCome.value) {
      if (isNowHour && isNowMinutes) {
        arg.el.classList.add("mj-calendar-timegrid-".concat(type, "__nowhour-label"));
      }

      return;
    }

    if (isNowHour) {
      arg.el.classList.add("mj-calendar-timegrid-".concat(type, "__nowhour-label"));
    }
  };

  var slotLaneDidMount = function slotLaneDidMount(arg) {
    // 這個callback才能存取arg.el
    var hours = arg.date.getHours();
    var minutes = arg.date.getMinutes();
    var now = new Date();
    var isNowHour = hours == now.getHours();
    var minutes15 = Math.floor(now.getMinutes() / 15) * 15;
    var isNowMinutes = minutes == minutes15;

    if (minutes == 15 || minutes == 45) {
      arg.el.classList.add("mj-calendar-timegrid-".concat(type, "__nowhour-mid-lane"));
    }

    if (isCome.value) {
      if (isNowHour && isNowMinutes) {
        arg.el.classList.add("mj-calendar-timegrid-".concat(type, "__nowhour-lane"));
      }

      return;
    }

    if (isNowHour) {
      arg.el.classList.add("mj-calendar-timegrid-".concat(type, "__nowhour-lane"));

      if (minutes != 0) {
        arg.el.classList.add("mj-calendar-timegrid-".concat(type, "__nowhour-mid-lane"));
      }
    }
  };

  return {
    duration: duration,
    minTime: minTime,
    maxTime: maxTime,
    slotLabelInterval: slotLabelInterval,
    labelContent: labelContent,
    labelDidMount: labelDidMount,
    slotLaneDidMount: slotLaneDidMount
  };
}