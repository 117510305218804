/* unplugin-vue-components disabled */import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/CustomerTypeIcon.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/EventTypeIcon.vue';
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "mj-calendar-timegrid-event__infbar"
};
var _hoisted_2 = {
  class: "mj-calendar-timegrid-event__icon"
};
var _hoisted_3 = {
  class: "mj-calendar-timegrid-event__content"
};
var _hoisted_4 = {
  class: "mj-calendar-timegrid-event__content"
};
var _hoisted_5 = {
  key: 0,
  class: "mj-calendar-timegrid-event__content"
};
import { padNum } from '@/js/utils';
import moment from "moment";
export default {
  __name: 'MJCalendarTimegridEvent',
  props: {
    /**
     * @property {OrderEvent}
     */
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isGridDay: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var store = useStore();
    var isCome = computed(function () {
      return store.getters['auth/isCome'];
    });
    var color = computed(function () {
      var _props$data, _props$data$room;

      if (isCome && (_props$data = props.data) !== null && _props$data !== void 0 && (_props$data$room = _props$data.room) !== null && _props$data$room !== void 0 && _props$data$room.color) return props.data.room.color;
      return props.data.color;
    });
    var time = computed(function () {
      return props.data.startAt.map(function (v) {
        return padNum(v, 2);
      }).join(':') + '-' + props.data.endAt.map(function (v) {
        return padNum(v, 2);
      }).join(':');
    });
    var birthday = computed(function () {
      var _props$data2, _props$data2$customer;

      if (!((_props$data2 = props.data) !== null && _props$data2 !== void 0 && (_props$data2$customer = _props$data2.customerData) !== null && _props$data2$customer !== void 0 && _props$data2$customer.mbBirthday)) return '';
      return moment(props.data.customerData.mbBirthday, 'YYYY/MM/DD').format('M/D');
    });
    return function (_ctx, _cache) {
      var _props$data3, _props$data3$room;

      var _component_EventTypeIcon = __unplugin_components_0;

      var _component_CustomerTypeIcon = __unplugin_components_1;

      var _component_b_button = _resolveComponent("b-button");

      var _directive_b_tooltip = _resolveDirective("b-tooltip");

      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["mj-calendar-timegrid-event", {
          'is-assistant': __props.data.assistantId
        }]),
        style: _normalizeStyle({
          '--mj-calendar-timegrid-event-color': null,
          '--mj-calendar-timegrid-event-bg': __props.data.deletedAt ? '#3b3b3b' : __props.data.assistantColor ? __props.data.assistantColor : _unref(color) || null
        })
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_EventTypeIcon, {
        type: __props.data.smsStatus
      }, null, 8, ["type"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data.customerType, function (val, key) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: key
        }, [val ? (_openBlock(), _createBlock(_component_CustomerTypeIcon, {
          key: 0,
          type: Number(key)
        }, null, 8, ["type"])) : _createCommentVNode("", true)], 64);
      }), 128))]), _createElementVNode("div", _hoisted_3, [_createTextVNode(_toDisplayString(_unref(birthday)) + " " + _toDisplayString(__props.data.name) + " " + _toDisplayString(__props.data.customerPhone) + " ", 1), __props.isGridDay ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(_toDisplayString(_unref(time)) + " ", 1), (_props$data3 = __props.data) !== null && _props$data3 !== void 0 && (_props$data3$room = _props$data3.room) !== null && _props$data3$room !== void 0 && _props$data3$room.roomName ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(" (" + _toDisplayString(__props.data.room.roomName) + ") ", 1)], 64)) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data.hints, function (item, index) {
        return _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: index,
          class: "event-hints-btn",
          variant: "link"
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("i", {
              class: _normalizeClass("fa ".concat(item.icon))
            }, null, 2)];
          }),
          _: 2
        }, 1024)), [[_directive_b_tooltip, void 0, void 0, {
          hover: true
        }]]);
      }), 128)), _createTextVNode(" " + _toDisplayString(__props.data.content), 1)]), !__props.isGridDay ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(time)), 1)) : _createCommentVNode("", true)], 6);
    };
  }
};