/* unplugin-vue-components disabled */import "core-js/modules/es.array.concat.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-912c86a8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "memo"
};
import moment from 'moment';
import { numberToZHWeekday } from '@/js/utils';
import { padNum } from '@/js/functions';
export default {
  __name: 'MJCalendarLockEvent',
  props: {
    data: {
      type: Object,

      /** @type {() => LockEvent|{}} */
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var store = useStore();
    var dateInfoText = computed(function () {
      if (!props.data) return;
      if (!props.data.date) return;
      if (!props.data.startAt && !Array.isArray(props.data.startAt)) return;
      if (!props.data.endAt && !Array.isArray(props.data.endAt)) return;
      var data = props.data;
      var mmDate = moment(data.date);
      var mmDateFormat = mmDate.format('YYYY.MM.DD');
      var zhNumber = numberToZHWeekday(mmDate.day());
      var startAtFormat = "".concat(padNum(data.startAt[0], 2), ":").concat(padNum(data.startAt[1], 2));
      var endAtFormat = "".concat(padNum(data.endAt[0], 2), ":").concat(padNum(data.endAt[1], 2));
      return "".concat(mmDateFormat, " (").concat(zhNumber, ")<br> ").concat(startAtFormat, "~").concat(endAtFormat);
    });

    function onClick() {
      if (!store.getters['auth/isPermissionGroupNoHQ_ANG']) return;
      appSwalCustomDialog.confirm({
        title: '要解除鎖定嗎?',
        content: dateInfoText.value,
        buttonOkText: '解除',
        buttonOkColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-theme-color'),
        onCancel: function onCancel() {
          console.log('取消');
        },
        onOk: function onOk() {
          console.log('刪除');
          store.dispatch('calendar/removeLockEvent', {
            events: [props.data.id]
          });
        }
      });
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "mj-calendar-lock-event",
        onClick: onClick
      }, [_createElementVNode("div", _hoisted_1, _toDisplayString(__props.data.memo), 1)]);
    };
  }
};